/* PhotoGallery */
.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.gallery-item {
  flex: 0 0 calc(33.333% - 10px);
  margin-bottom: 10px;
  width: 300px;
  height: 250px;
  position: relative;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.gallery-item:hover img {
  transform: scale(1.05);
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .gallery-item {
    flex: 0 0 calc(50% - 10px);
  }
}

/* Responsive styles for even smaller screens */
@media (max-width: 480px) {
  .gallery-item {
    flex: 0 0 calc(100% - 10px);
  }
}
