// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// Pages styles
@import 'pages/gallery';

.text-xs {
  font-size: 14px;
}

.custom-margin {
  margin-top: -9rem;
  margin-left: -0.75rem;
}
